const parsePlaceAddress = (place) => {
  const propsByType = (place.address_components || []).reduce((list, item) => {
    item.types.forEach((type) => {
      list[type] = item;
    });
    return list;
  }, {});

  return propsByType;
};

let isLoading = false;

const initInput = (input, callback) => {
  if (isLoading) {
    setTimeout(() => initInput(input, callback), 1000);
    return;
  }

  const autocomplete = new window.google.maps.places.Autocomplete(input);
  window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
    const place = autocomplete.getPlace();
    const address = parsePlaceAddress(place);
    const response = {
      name: place.name,
      city: address.locality || address.sublocality || address.sublocality_level_1 || address.neighborhood,
      ...address,
    };
    callback(response);
  });
};

export default (input, callback) => {
  const apiKey = useRuntimeConfig().public.googleMapsApiKey;

  if (!apiKey) {
    console.warn('Missing Google API key');
    return false;
  }

  const uid = getCurrentInstance().uid;
  window[`initInput${uid}`] = () => initInput(input, callback);

  if (window.google || isLoading) {
    initInput(input, callback);
  } else {
    isLoading = true;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&callback=initInput${uid}`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      isLoading = false;
    };
    document.body.appendChild(script);
  }

  return true;
};
